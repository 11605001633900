import Vue from 'vue'
import Vuex from 'vuex'

import apiRoutes from '@/js/api-routes'

Vue.use(Vuex)

export const Employees = {
  namespace: true,
  name: 'employees',
  state: {
		employeeList: [], // All Employees for Superuser view. All merchants included
		employeeProfile: {},
		merchantEmployeeList: [], // Employees for Merchant only
		selectedEmployeeProfile: {},
		selectedMerchantEmployeeList: [] // Employees from selected account

  },
  mutations: {
		SET_EMPLOYEE_LIST(state, payload) {
			Vue.set(state, 'employeeList', payload);
		},
		PUSH_NEW_EMPLOYEE(state, payload) {
			if(Object.keys(state.employeeProfile).length === 0) {
				state.employeeList.push(payload)
			} else {
				state.merchantEmployeeList.push(payload)
			}
		},
		SET_EMPLOYEE_PROFILE(state, payload) {
			Vue.set(state, 'employeeProfile', payload);
		},
		SET_MERCHANT_EMPLOYEE_LIST(state, payload) {
			// console.log('SET_MERCHANT_EMPLOYEE_LIST payload', payload)
			Vue.set(state, 'merchantEmployeeList', payload);
			// console.log('state.merchantEmployeeList', state.merchantEmployeeList)
		},
		SET_SELECTED_MERCHANT_EMPLOYEE_LIST(state, payload) {
			// console.log('SET_MERCHANT_EMPLOYEE_LIST payload', payload)
			if(typeof payload == 'object' && payload.length == undefined) {
				state.selectedMerchantEmployeeList.push(payload)
			}else {
				Vue.set(state, 'selectedMerchantEmployeeList', payload);
			}
			// console.log("state.selectedMerchantEmployeeList",state.selectedMerchantEmployeeList);
			
			// console.log('state.merchantEmployeeList', state.merchantEmployeeList)
		},
		SET_SELECTED_EMPLOYEE(state, payload) {
			Vue.set(state, "selectedEmployeeProfile", payload)	

			//Find employee in table and update info
			const arrIndx = state.employeeList.findIndex((itm) => itm.id === payload.id)
			state.employeeList.splice(arrIndx, 1, payload)
		},
		CLEAR_EMPLOYEE_STATE(state, payload) {
			state.employeeList= [] // All Emoployees for Superuser view
			state.employeeProfile= {}
			state.merchantEmployeeList= []
			state.selectedEmployeeProfile= {}
			state.selectedMerchantEmployeeList= []
		}


  },
  actions: {
		//POST and CREATE Item
		async POSTEmployee({commit, dispatch, rootState}, payload) {
			return new Promise( async (resolve, reject) => {
				try {
					let endpoint = 'employees/';
					let type = 'Create New Employee';
					let response = await apiRoutes.POSTItem(dispatch, rootState,payload, endpoint, type)
					// console.log('POSTEmployee response', response);
					if(response.status === 201) {
						// console.log("Successful");
						commit('PUSH_NEW_EMPLOYEE', response.data);
						return resolve(response)
					} else {
						return reject(response)
					}
				} catch (error) {
					return reject(error)
				}       
			}).catch(error => {
				return error;
			});
		},
		//PATCH / UPdate
		async PATCHEmployee({commit, dispatch, rootState}, payload) {
			return new Promise( async (resolve, reject) => {
				try {
					let endpoint = 'employees/';
					let type = 'Update Employee';
					let response = await apiRoutes.PATCHItemById(dispatch, rootState,payload, endpoint, type)
					// console.log('PATCHEmployee response', response);
					if(response.status === 200 || response.status === 201) {
						// console.log("Successful");
						commit('SET_SELECTED_EMPLOYEE', response.data);
						return resolve(response)
					} else {
						return reject(response)
					}
				} catch (error) {
					return reject(error)
				}       
			}).catch(error => {
				return error;
			});
		},

		//GET All Employees (Globally)
		async GETEmployeeList({commit, dispatch, rootState}) {
			return new Promise( async (resolve, reject) => {
				try {
					let endpoint = 'employees-list/';
					let type = 'Get Employees List';
					let response = await apiRoutes.GETList(dispatch, rootState, endpoint, type);
					// console.log('GETEmployeesList response', response);
					commit('SET_EMPLOYEE_LIST', response.data);
					return resolve(response.data)

				} catch(error) {
					// console.error('TryCatch Error error.response', error.response)
					return reject(error)
				}
			}).catch(error => {
				// console.error('Promise Error error.response', error.response)
				return error;
			});
		},
		//FILTER Merchant Employees by User_id
		async GETMerchantEmployeeList({commit, dispatch, rootState}, payload) {
			return new Promise( async (resolve, reject) => {
				// console.log("GETMerchantEmployeeList", payload);
				try {
					let endpoint = 'employees-list/?merchant=';
					let type = 'Get Merchant Employees List';
					let response = await apiRoutes.FILTERListById(dispatch, rootState, payload, endpoint, type);
					// console.log('GETMerchantEmployeeList response', response);
					commit('SET_MERCHANT_EMPLOYEE_LIST', response.data);
					return resolve(response.data)

				} catch(error) {
					// console.error('TryCatch Error error.response', error.response)
					return reject(error)
				}
			}).catch(error => {
				// console.error('Promise Error error.response', error.response)
				return error;
			});
		},
		GETSelectedMerchantEmployeeList({commit, dispatch, rootState}, payload) {
			return new Promise( async (resolve, reject) => {
				try {
					let endpoint = 'employees-list/?merchant=';
					let type = 'Get Merchant Employees List';
					let response = await apiRoutes.FILTERListById(dispatch, rootState, payload, endpoint, type);
					// console.log('GETMerchantEmployeeList response', response);
					commit('SET_SELECTED_MERCHANT_EMPLOYEE_LIST', response.data);
					return resolve(response.data)

				} catch(error) {
					// console.error('TryCatch Error error.response', error.response)
					return reject(error)
				}
			}).catch(error => {
				// console.error('Promise Error error.response', error.response)
				return error;
			});
		},

		//GET Selected Items of Subordinate accounts
		GETSelectedEmployeeById ({ commit, dispatch, rootState }, payload) {
			// console.log('GETSelectedEmployeeById payload', payload)
			return new Promise(async (resolve, reject) => {
				try {
					const endpoint = 'employees/'
					const type = 'Get Employee Profile'
					const response = await apiRoutes.GETProfileById(dispatch, rootState, payload, endpoint, type)
					if (response.status === 200) {
						// console.log('GETSelectedEmployeeById response', response)
						commit('SET_SELECTED_EMPLOYEE', response.data)
						return resolve(response.data)
					} else {
						return reject({ message: response })
					}
				} catch (error) {
					return reject(error)
				}
			}).catch(error => {
				// console.log('error', error)
				if (error.response) {
					// console.log('error.response', error.response)
					// dispatch('updateNotification', error.response);
					return reject(error)
				}
			})
		},
		

		// FILTER Employee Profile Profile By User_Id
    FILTEREmpoyeeProfileById ({ commit, dispatch, rootState }, payload) {
      // console.log('FILTEREmpoyeeProfileById payload', payload)
      return new Promise(async (resolve, reject) => {
        try {
          const endpoint = 'employees/?user__id='
          const type = 'Get Employee Employee Profile'
          const response = await apiRoutes.FILTERListById(dispatch, rootState, payload, endpoint, type)
          if (response.status === 200) {
            // console.log('FILTEREmpolyeeProfileById response', response)
            commit('SET_EMPLOYEE_PROFILE', response.data[0])
            return resolve(response.data[0])
          } else {
            return reject({ message: response })
          }
        } catch (error) {
          return reject(error)
        }
      }).catch(error => {
        // console.log('error', error)
        if (error.response) {
          // console.log('error.response', error.response)
          // dispatch('updateNotification', error.response);
          return reject(error)
        }
      })
    },
		clearEmployeeState({ commit, dispatch, rootState }) {
			commit("CLEAR_EMPLOYEE_STATE")
		},

  },
  getters: {
		GET_EMPLOYEE_LIST(state) {
			return state.employeeList
		},
		GET_MERCHANT_EMPLOYEES_LIST(state) {
			return state.merchantEmployeeList.filter(itm => itm.user_obj.is_active)
		},
		GET_SELECTED_MERCHANT_EMPLOYEES_LIST(state) {
			// console.log("state.selectedMerchantEmployeeList", state.selectedMerchantEmployeeList);
			return state.selectedMerchantEmployeeList.filter(itm => itm.user_obj.is_active)
		},
		GET_EMPLOYEE_PROFILE(state) {
			return state.employeeProfile
		},
		GET_SELECTED_EMPLOYEE_PROFILE(state) {
			return state.selectedEmployeeProfile
		}
  }
}
