import Vue from 'vue'
import Vuex from 'vuex'

import apiRoutes from '@/js/api-routes'

Vue.use(Vuex)

export const Users = {
  namespace: true,
  name: 'users',
  state: {
		userList: [],
		selectedUserProfile: {}
  },
  mutations: {
		SET_SELECTED_USER(state, payload) {
			state.selectedUserProfile = payload
		}
  },
  actions: {
		//POST and CREATE Item
		async POSTUser({commit, dispatch, rootState}, payload) {
			return new Promise( async (resolve, reject) => {
				try {
					let endpoint = 'users/';
					let type = 'Create New User';
					let response = await apiRoutes.POSTItem(dispatch, rootState,payload, endpoint, type)
					// console.log('POSTUser response', response);
					if(response.status === 201) {
						// console.log("Successful response", response);
						return resolve(response)
					} else {
						return reject(response)
					}
				} catch (error) {
					return reject(response)
				}       
			}).catch(error => {
				return error;
			});
		},

    // GET User Profile By Id
    GETUserProfileById ({ commit, dispatch, rootState }, payload) {
      // console.log('GETUserProfileById')
      return new Promise(async (resolve, reject) => {
        try {
          // console.log('GETUserProfileById payload', payload)
          let endpoint = 'users/'
          let type = 'Get User Profile'
          let response = await apiRoutes.GETProfileById(dispatch, rootState, payload, endpoint, type)
          if (response.status === 200) {
            // console.log('GETUserProfileById response', response)
            // commit('SET_USER_PROFILE', response.data)
						return resolve(response.data)
						
          } else {
            return resolve()
          }
        } catch (error) {
          return reject(error)
        }
      }).catch(error => {
        // console.log('error', error)
        if (error.response) {
          // console.log('error.response', error.response)
          // dispatch('updateNotification', error.response);
          return reject(error)
        }
      })
		},
		//PATCH Base User Profile
		PATCHBaseUser({commit, dispatch, rootState}, payload) {
			return new Promise( async (resolve, reject) => {
				try {
					let endpoint = 'users/';
					let type = 'Update Userser';
					let response = await apiRoutes.PATCHItemById(dispatch, rootState,payload, endpoint, type)
					// console.log('PATCHUser response', response);
					
					if(response.status === 200 || response.status === 201) {
						// console.log("Successful");

						return resolve(response)
					} else {
						return reject(response)
					}
				} catch (error) {
					return reject(error)
				}       
			}).catch(error => {
				return error;
			});
		},

  },
  getters: {
		GET_SELECTED_USER_PROFILE(state) {
			return state.selectedUserProfile
		}
  }
}
