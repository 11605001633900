<template>
	<div class="row justify-center">
		
		<!--Credit Card Form-->
		<template>
			<div class="w-full">
				<v-form
					ref="validateFormRef"
					v-model="validForm">

					<div class="row justify-center my-3">
						<div class="col-12 px-2">
							<div class="field-title">
								Card Number
								<div class="row">
									<v-img
										max-height="200"
										max-width="200"
										src="@/assets/static/4_Card_color_horizontal.png"
									></v-img>
									<span class="flex items-center">
										{{ cardType }}
									</span>
								</div>
								
							</div>
							<v-text-field
								v-model="creditCardForm.ssl_card_number"
								v-mask="'#### #### #### ####'"
								:rules="creditCardRules"
								@input="tempCCData = $event"
								outlined>
							</v-text-field>
			
								<!-- <DxTextBox
									styling-mode="filled"
									:height="50"
									v-model="creditCardForm.ssl_card_number"
									class="card-number-field"
									:mask-rules="cardNumberRules"
									mask="X000 0000 0000 0000"
									max-length="16"
								/> -->
						</div>
					</div>
					<div class="row justify-center my-3">
						<div class="col-6 px-2">
							<div class="field-title">
								<span>
									Exp Date
								</span>	
								<span>(MM</span><span>/</span><span>YY)</span>
							</div>
							<v-text-field
								v-model="creditCardForm.ssl_exp_date"
								v-mask="'## / ##'"
								:rules="expDateRules"
								outlined>
							</v-text-field>
								<!-- <DxTextBox
									styling-mode="filled"
									:height="50"
									:value.sync="creditCardForm.card_ssl_exp_date"
									@input="creditCardForm.card_ssl_exp_date = $event.event.target.value"
									:mask-rules="expRules"
									mask="X0/00"
									class="custom-exp"
									max-length="4"
								/> -->
						</div>
						<div class="col-6 px-2">
							<div class="field-title">
								CVV
							</div>
							<v-text-field
								v-model="creditCardForm.ssl_cvv2cvc2"
								v-mask="'####'"
								:rules="cvvRules"
								outlined>
							</v-text-field>
								<!-- <DxTextBox
									styling-mode="filled"
									:height="50"
									v-model="creditCardForm.card_cvv"
									placeholder="123"
									max-length="4"
									class="cvv-field"
								/> -->
						</div>
					</div>

				</v-form>
			</div>

		</template>
		<!--END Credit Card Popup-->
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import axios from "axios"
import _ from "lodash"
import moment from 'moment'

//Mixins
import { FormMixins } from "@/mixins/form-mixins"

//Data
import { countries } from "@/js/countries"

export default {
  name: 'creditCardFormComponent',
  components: {

  },
  mixins: [
		FormMixins,
  ],
  props: {
		creditCardForm: {
			type: Object,
			required: true
		}
  },
  data () {
    return {
			// Main Data
			countries: countries,
			validCardholder: false,

			//Form Values
			validForm: false,
			cardType: null,

			//Debounce Function to api BinList.net
			tempCCData: null,
			debouncedFunction: null,
			

    }
  },
  methods: {
    testButton() {
			console.log("selected", selected)
		},
		CardBinDetails() {
			if(this.creditCardForm.ssl_card_number.startsWith("4")) {
				this.$emit("CardBinDetails", "VISA")
				// console.log("VISA")
			}	else if(this.creditCardForm.ssl_card_number.startsWith("34")) {
				this.$emit("CardBinDetails", "AMEX")
				// console.log("AMEX")
			} else if(this.creditCardForm.ssl_card_number.startsWith("35")) {
				this.$emit("CardBinDetails", "AMEX")
				// console.log("AMEX")
			} else if(this.creditCardForm.ssl_card_number.startsWith("36")) {
				this.$emit("CardBinDetails", "AMEX")
				// console.log("AMEX")
			} else if(this.creditCardForm.ssl_card_number.startsWith("37")) {
				this.$emit("CardBinDetails", "AMEX")
				// console.log("AMEX")
			}	else if(this.creditCardForm.ssl_card_number.startsWith("5")) {
				this.$emit("CardBinDetails", "MASTERCARD")
				// console.log("MASTERCARD")
			}	else if(this.creditCardForm.ssl_card_number.startsWith("6011")) {
				this.$emit("CardBinDetails", "DISCOVER")
				// console.log("DISCOVER")
			} else if(this.creditCardForm.ssl_card_number.startsWith("65")) {
				this.$emit("CardBinDetails", "DISCOVER")
				// console.log("DISCOVER")
			} else if(this.creditCardForm.ssl_card_number.startsWith("38")) {
				this.$emit("CardBinDetails", "DINERSCLUB")
				// console.log("DINERSCLUB")
			} else if(this.creditCardForm.ssl_card_number.startsWith("36")) {
				this.$emit("CardBinDetails", "DINERSCLUB")
				// console.log("DINERSCLUB")
			}
			
		},
		validate() {
			return this.$refs.validateFormRef.validate()
		}


  },
  computed: {
    ...mapState([]),
		...mapGetters([]),


	},
	watch: {
		tempCCData: function(nVal, oVal) {
			// console.log("tempCCData changed")
			// console.log("oVal", oVal)
			// console.log("nVal", nVal)
			if(nVal.length === 4) {
				this.debouncedFunction()
			}
			
		}
	},
	created() {
		this.debouncedFunction = _.debounce(this.CardBinDetails, 500)
	},
  mounted () {


	},

}
</script>

<style scoped lang="scss">
.custom-number {
	width: 150px;
}
.dense-rows {
	height: 30px;
}
.total-col {
	width: 10%;
}
</style>
