import Vue from 'vue'
import VueRouter from 'vue-router'
import Router from 'vue-router'
import store from '@/store/'

//Import NavLinks
import { web, main, superuser, merchant } from "@/routes/app-navigation"
// console.log('store', store)

store._modules.root.state.Notifications.isLoadPanelVisible = true

//Load User First
var varMenu = null;
export const userProfile = async () => {
	return new Promise(async (resolve, reject) => {
		var User = store.getters.GET_USER_PROFILE
		// console.log('store.getters.GET_USER_PROFILE', store.getters.GET_USER_PROFILE)
		if(Object.keys(store.getters.GET_USER_PROFILE).length === 0) {
			User = await store.dispatch('GETUserProfile')
			// console.log("User", User)
			// console.log('store.getters.GET_USER_PROFILE 2', store.getters.GET_USER_PROFILE)
			return resolve(User)
		} else {
			return resolve(User)
		}
	})
}

import Login from '../views/login-view'
import PermissionDenied from '../views/permission-denied'
import accountLocked from '../views/locked-account'
import accountClosed from '../views/closed-account'
import Reset from '../views/password-reset'
import leftDrawer from '@/components/navigation/left-drawer-component'

// Superuser pages
import superUserDashboard from '@/pages/superuser-portal/dashboard'
import superUserSuperusers from '@/pages/superuser-portal/superusers'
import superUserMerchants from '@/pages/superuser-portal/merchants'
import superUserEmailNotifications from '@/pages/superuser-portal/email-notifications'
import superUserSettings from '@/pages/superuser-portal/settings'

// Merchant pages
import merchantDashboard from '@/pages/merchant-portal/dashboard'
import merchantEmployees from '@/pages/merchant-portal/employees'
import merchantSettings from '@/pages/merchant-portal/settings'
import customerEmailPortal from '@/pages/merchant-portal/email'
import merchantTransactions from '@/pages/merchant-portal/transactions'
import postTransactions from '@/pages/merchant-portal/post-transactions'

//Profile Pages
//See below

//Website
import websiteHomePage from "@/views/website/home/index.vue"
import hostedPaymentPage from "@/views/e-commerce/hosted-payment-view"
import receiptPage from "@/views/e-commerce/receipt-view"

//404 Not Found
import NotFound from "@/views/404"

Vue.use(VueRouter)

export const router = new VueRouter({
  mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
	},
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login
		},
		{
      path: '/password-reset',
      name: 'Password Reset',
      component: Reset
		},
		{
      path: '/denied',
      name: 'Permission Denied',
      component: PermissionDenied
    },
		{
      path: '/locked',
      name: 'Account Locked',
      component: accountLocked
    },
		{
      path: '/closed',
      name: 'Account Closed',
      component: accountClosed
    },
    {
      path: '/secured',
      name: 'Secured',
			component: Login,
			beforeEnter: (to, from, next) => {
				// console.log("from", from)
				// console.log("to", to)
				// console.log("store", store)
				// console.log("store.getters.GET_USER_PROFILE", store.getters.GET_USER_PROFILE)
				
				if (from.name == null) router.push({name: Login} )
				else if (from.name === 'Login' && store.getters.GET_USER_PROFILE.is_superuser) next({ name: 'Superuser', replace: true })
				else if(from.name === 'Login' && store.getters.GET_USER_PROFILE.is_merchant) next({ name: 'Merchant', replace: true })
				else if(from.name === null) next({ path: from.query.redirect, replace: true })
				// else	next({name:to.query, replace: true})
				else next()
			}
		},
    
		
		//Website Payment Pages
		{
			path: '/payment',
			name: 'Hosted Payment',
			meta: { requiresAuth: false },
			component: () => import("@/layouts/slimline/index.vue"),
			children: [
				{
					path: '',
					name: 'Payment Page',
					meta: { requiresAuth: false },
					components:  {
						default: hostedPaymentPage
					},
				},
				{
					path: 'receipt',
					name: 'Transaction Receipt',
					meta: { requiresAuth: false },
					components:  {
						default: receiptPage
					},
				},
				
			],
		},

		//Website Layout Pages
		{
			path: '/',
			name: 'Web Home',
			meta: { requiresAuth: false,
				navBar: web },
			component: () => import('@/layouts/website/index.vue'),
			children: [
				{
					path: '',
					name: 'Home',
					meta: { requiresAuth: false,
						navBar: web
						 },
					components: {
						web: () => import("@/views/website/home/index.vue"),
					}
				},
				{
				  path: 'about',
				  name: 'About',
				  components: {
						web: () => import('@/views/website/about/index.vue'),
					},
					meta: { src: require('@/assets/static/website/about.jpg'),
						navBar: web },
				},
				{
				  path: 'contact-us',
				  name: 'Contact',
				  components: {
						web: () => import('@/views/website/contact-us/index'),
					},
					meta: { src: require('@/assets/static/website/contact.jpg'),
						navBar: web },
				},
				// {
				//   path: 'pro',
				//   name: 'Pro',
				//   component: () => import('@/views/pro/Index.vue'),
				//   meta: { src: require('@/assets/pro.jpg') },
				// },
				// {
				//   path: '*',
				//   name: 'FourOhFour',
				//   component: () => import('@/views/404/Index.vue'),
				// },
			],
		},
		
		// Admin Layout Pages
		{
			path: '/admin',
			name: 'Admin Home',
			meta: { requiresAuth: true },
			component: () => import('@/layouts/admin-portal/index.vue'),
			children: [
				{
					path: '',
					name: 'Admin',
					meta: { requiresAuth: true,
						navBar: superuser },
					components: {
						default: () => import("@/views/admin-portal/home/index.vue"),
						left: leftDrawer
					},
					// beforeEnter: (to, from, next) => {
					// 	if(store.getters.GET_USER_PROFILE.is_merchant) {
					// 		next({name: "Merchant", replace: true})
					// 	}
					// 	if(store.getters.GET_USER_PROFILE.is_superuser) {
					// 		next()
					// 	}
					// }
				},
				{
					path: '/profile',
					name: 'Profile',
					meta: { requiresAuth: true,
						navBar: merchant
					},
					components: {
						default: () => import("@/views/profiles/profile-view"),
						left: leftDrawer
					}
				},
				
				// Superuser Pages
				{
					path: '/superuser',
					name: 'Superuser',
					meta: { requiresAuth: true,
						checkPermissions: true,
						navBar: superuser },
					components: {
						default: superUserDashboard,
						left: leftDrawer
					}
				},
				{
					path: '/superuser/superusers',
					name: 'Superuser Superusers',
					meta: { requiresAuth: true,
						checkPermissions: true,
						navBar: superuser },
					components: {
						default: superUserSuperusers,
						left: leftDrawer
					}
				},
				{
					path: '/superuser/merchants',
					name: 'Superuser Merchants',
					meta: { requiresAuth: true,
						checkPermissions: true,
						navBar: superuser },
					components: {
						default: superUserMerchants,
						left: leftDrawer
					}
				},
				{
					path: '/superuser/email/notifications',
					name: 'Superuser Email Notifications',
					meta: { requiresAuth: true,
						checkPermissions: true,
						navBar: superuser },
					components: {
						default: superUserEmailNotifications,
						left: leftDrawer
					}
				},
				{
					path: '/superuser/settings',
					name: 'Superuser Settings',
					meta: { requiresAuth: true,
						checkPermissions: true,
						navBar: superuser },
					components: {
						default: superUserSettings,
						left: leftDrawer
					}
				},
			
				// Merchant Pages
				{
					path: '/merchant',
					name: 'Merchant',
					meta: { requiresAuth: true,
						checkPermissions: true,
						navBar: merchant },
					components: {
						default: merchantDashboard,
						left: leftDrawer
					}
				},
				{
					path: '/merchant/employees',
					name: 'Merchant Employees',
					meta: { requiresAuth: true,
						checkPermissions: true,
						navBar: merchant },
					components: {
						default: merchantEmployees,
						left: leftDrawer
					}
				},
				{
					path: '/merchant/transactions',
					name: 'Merchant Transactions',
					meta: { requiresAuth: true,
						checkPermissions: true,
						navBar: merchant },
					components: {
						default: merchantTransactions,
						left: leftDrawer
					}
				},
				{
					path: '/merchant/post',
					name: 'Post Transactions',
					meta: { 
						requiresAuth: true,
						checkPermissions: true,
						navBar: merchant 
					},
					components: {
						default: postTransactions,
						left: leftDrawer
					}
				},
				{
					path: '/merchant/settings',
					name: 'Merchant Settings',
					meta: { requiresAuth: true,
						checkPermissions: true,
						navBar: merchant },
					components: {
						default: merchantSettings,
						left: leftDrawer
					}
				},
				
				{
					path: '/merchant/email',
					name: 'Merchant Email',
					meta: { requiresAuth: true,
						checkPermissions: true,
						navBar: merchant },
					components: {
						default: customerEmailPortal,
						left: leftDrawer
					}
				},

			],
		},
		{
			// matches everything else  
			path: '*',
			name: '404',
			component: NotFound
		}
  ]
})

//* *****************Route Guards**************************** */

router.beforeEach(async (to, from, next) => {
	// console.log("User checkRoutes2")
	// console.log("to", to)
	// console.log("from", from)

	const userData = await userProfile()
	// console.log('userData', userData)

	if(userData) {
		if(to.matched.some(record => record.meta.navBar === 'variable')) {
			if(userData.is_merchant) {
				to.meta.navBar = JSON.parse(JSON.stringify(merchant))
			} else {
				to.meta.navBar = JSON.parse(JSON.stringify(superuser))
			}
		}
	}

	store._modules.root.state.Notifications.isLoadPanelVisible = false

	if (to.matched.some(record => record.meta.requiresAuth)) {
		
		// console.log('store.getters.IS_AUTHENTICATED', store.getters.IS_AUTHENTICATED)
		// console.log('store.getters.GET_FULL_PROFILE', store.getters.GET_FULL_PROFILE)
		const fullProfile = store.getters.GET_FULL_PROFILE
		var hasFullProfile = false
		if(fullProfile) {
			hasFullProfile = Object.keys(fullProfile).length != 0
			// console.log('hasFullProfile', hasFullProfile)
		}
		
		
		// Load User from Local Storage if not authenticated
		if(!store.getters.IS_AUTHENTICATED) {
			// console.log("User2", userData)

			if(userData === undefined ||Object.keys(userData).length === 0) {

				next({
					path: '/login',
					query: { redirect: to.fullPath }
				})
			
			} else {
				if(!hasFullProfile) {
					await store.dispatch('preFetchLogin', userData)
				}
				next()
			} 
		} else {
			if(!hasFullProfile) {
				// console.log("Getting hasFullProfile")
				await store.dispatch('preFetchLogin', userData)
			}
			next()
		}
	} else {
		next() // make sure to always call next()!
	}
})



