<template>
	<div class="main-container">


		<!-- NavBar -->
		<websiteNavbarComponent 
			:scrollOffset="scrollHeight" 
			:formData="this.merchantData" />

		<div class="row justify-center">
			<div class="container">
				<div class="row">
					<div class="col-12">
						<div class="headline-2 mb-5">
							{{ this.merchantData.legal_name }}
						</div>
						

						<!-- Payment Success -->
						<template>
							<div class="row">
								<div class="col-12">
									<p>
										Approval #{{ transactionData.approval_code }}
									</p>
									<p>
										Thank you {{ customerData.first_name }} {{ customerData.last_name }}
									</p>
								</div>
							</div>
							<div class="row justify-center">
								<div class="col-12 px-5">
									<div class="row p-3 my-3 border-2 border-orange-600 border-opacity-25 w-full">
										<div class="col-2">
											<v-img
												height="75"
												width="75"
												src="@/assets/static/AnimatedGreenCheck.gif"
											></v-img>
										</div>
										<div class="col-6">
											<div class="title">
												Your order is approved!
											</div>
											<p>Please print this page as your receipt</p>
										</div>

									</div>									
								</div>
							</div>
							<div class="row justify-center">
								<div class="col-12 px-5">
									<v-card>
										<v-card-title class="bg-primary-darker text-white">
											Customer Information
										</v-card-title>
										<div class="row">
											<div class="col-6">
												<div class="subtitle">
													Contact Information
												</div>
												<p>
													{{ customerData.email }}
												</p>
											</div>
											<div class="col-6">
												<div class="row">
													<div>
														<div class="subtitle mr-3">Payment Method</div>
													</div>
													<div>
														<div v-if="cardScheme">
															<span class="mr-2" v-if="cardScheme === 'VISA'">
																<fa-icon :icon="['fab', 'cc-visa']" size="2x" class="text-primary"></fa-icon>
															</span>
															<span class="mr-2" v-if="cardScheme === 'MASTERCARD'">
																<fa-icon :icon="['fab', 'cc-mastercard']" size="2x" class="text-primary"></fa-icon>
															</span>
															<span class="mr-2" v-if="cardScheme === 'DISCOVER'">
																<fa-icon :icon="['fab', 'cc-discover']" size="2x" class="text-primary"></fa-icon>
															</span>
															<span class="mr-2" v-if="cardScheme === 'AMEX'">
																<fa-icon :icon="['fab', 'cc-amex']" size="2x" class="text-primary"></fa-icon>
															</span>
															<span class="mr-2" v-if="cardScheme === 'DINERSCLUB'">
																<fa-icon :icon="['fab', 'cc-diners-club']" size="2x" class="text-primary"></fa-icon>
															</span>
															<span class="mr-2" v-if="cardScheme === 'PAYPAL'">
																<fa-icon :icon="['fab', 'cc-paypal']" size="2x" class="text-primary"></fa-icon>
															</span>
															
														</div>
														<div v-else>
															<span class="mr-2">
																<fa-icon :icon="['far', 'credit-card']" size="2x" class="text-primary"></fa-icon>
															</span>
														</div>
													</div>
													<div>
															<span class="flex items-center">ending in {{ transactionData.ssl_card_number | last4 }}</span>
													</div>
												</div>							

												<p>
													Amount {{ transactionData.ssl_amount | dollar }}
												</p>
												
												
												
												
											</div>
										</div>
										<div class="row my-5">
											<div class="col-6">
												<div class="subtitle">
													Account Information
												</div>
												<p>Full Name: {{ customerData.first_name }} {{ customerData.last_name }}</p>
												<p>Email: {{ customerData.email }}</p>
												<p>Invoice: {{ customerData.invoice_number }}</p>
												<p>Customer ID: {{ customerData.customer_id }}</p>
											</div>
											<div class="col-6">
												<div class="subtitle">
													Cardholder Information
												</div>
								
												<p>{{ cardholderData.first_name }} {{ cardholderData.last_name }}</p>
												<p>{{ cardholderData.billing_address }} {{ cardholderData.billing_address2 }}</p>
												<p>{{ cardholderData.billing_city }} {{ cardholderData.billing_state }}, {{ cardholderData.billing_zip_code }}</p>
												<p>United States</p>
										
											</div>
										</div>
										<div class="row" v-if="$can('view', 'superuser')">
											<div class="col-3">
												<v-btn
													color="success"
													@click="testButton">
													All Data Test
												</v-btn>
											</div>
										</div>

									</v-card>
								</div>
							</div>
						</template>

						<!-- Payment Failure -->
						<!-- <template>
							<paymentFailureComponent
								:successfulResult="successfulResult" />
						</template> -->

					</div>

				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import moment from 'moment'

//Components
import websiteNavbarComponent from "@/components/website/nav-bar-component"
import creditCardFormComponent from "@/components/payment-processing/credit-card-form-component"
import checkoutListTableComponent from "@/views/e-commerce/components/checkout-list-table-component"
import cardholderDetailsComponent from "@/components/forms/cardholder-details-form"


//Mixins
import { FormMixins } from "@/mixins/form-mixins"
import { ElavonConvergeMixins } from "@/mixins/credit-card/elavon-converge"
 
export default {
  name: 'hostedPaymentPage',
  components: {
		websiteNavbarComponent,
		creditCardFormComponent,
		checkoutListTableComponent,
		cardholderDetailsComponent,
  },
  mixins: [
		FormMixins,
		ElavonConvergeMixins
  ],
  props: {

  },
  data () {
    return {
			// Main Data
			scrollHeight: 0,
			transactionData: {},
			merchantData: {},
			customerData: {},
			cardholderData: {},
			cardScheme: {}


			//Form Data
			


    }
  },
  methods: {
    testButton () {
			console.log('this.transactionData', this.transactionData.ssl_card_short_description)
			console.log('this.merchantData', this.merchantData)
			console.log('this.customerData', this.customerData)
			console.log('this.cardScheme', this.cardScheme)
		},

  },
  computed: {
    ...mapState([]),
    ...mapGetters([])

  },
  async mounted () {
		// console.log('this.$route', this.$route)
		let params = this.$route.params
		// console.log('params', params)
		if(params != undefined) {
			this.transactionData = params.transactionData
			this.merchantData = params.merchantData
			this.customerData = params.customerData
			this.cardholderData = params.cardholderData
			this.cardScheme = params.cardScheme
		}
		
  }
}
</script>
<style scoped lang="sass">
.main-container
	min-height: 82.4vh
</style>
