import Vue from 'vue'
import Vuex from 'vuex'

import apiRoutes from '@/js/api-routes'

Vue.use(Vuex)

export const Security = {
  namespace: true,
  name: 'security',
  state: {
		captchaProfile: {},

  },
  mutations: {
		SET_CAPTCHA(state, payload) {
			// console.log('SET_CAPTCHA payloac', payload)
			state.captchaProfile = payload
			// console.log('captchaProfile', state.captchaProfile)
		}

  },
  actions: {
		//POST and CREATE Item
		async POSTAPIKey({commit, dispatch, rootState}, payload) {
			return new Promise( async (resolve, reject) => {
				try {
					let endpoint = 'api-keys/';
					let type = 'Create / Update APIKey';
					let response = await apiRoutes.POSTItem(dispatch, rootState, payload, endpoint, type)
					// console.log('POSTAPIKey response', response);
					if(response.status === 201) {
						return resolve(response)
					}
				} catch (error) {
					return resolve()
				}       
			}).catch(error => {
				return error;
			});
		},
		async POSTCaptcha({commit, dispatch, rootState}, payload) {
			return new Promise( async (resolve, reject) => {
				try {
					let endpoint = 'captcha/';
					let type = 'Create / Update Captcha';
					let response = await apiRoutes.POSTItem(dispatch, rootState, payload, endpoint, type)
					// console.log('POSTCaptcha response', response);
					if(response.status === 201) {
						commit('SET_CAPTCHA', response.data)
						return resolve(response.data)
					}
				} catch (error) {
					return resolve()
				}       
			}).catch(error => {
				return error;
			});
		},
  },
  getters: {
		GET_SECURITY_LIST(state) {
			return state.securityList
		},
	}
}
