<template>
	<div class="flex justify-center">
		<div class="container mt-4">
			<DxScrollView
				show-scrollbar="always"
				width="100%"
				direction="horizontal"
				:scroll-by-thumb="true">
				
				<!-- Main Container Row -->
				<div class="row">
					<!-- Main Content Left Area -->
					<v-col cols="12" lg="6" class="px-2">
						<baseCompanyFormComponent
							ref="baseCompanyFormRef"
							@CompanyData="GetCompanyData"
							@clearCheckbox="clearCheckbox">

							<!-- //********************************* SLOTS ************************************** */ -->
							<template slot="title">
								Add New Merchants
							</template>
							<template slot="linked-accounts">
								<div class="row">
									<div class="col-12">
									<v-combobox
										v-model="linkedAccounts"
										:items="computeMerchantList"
										item-value="id"
										item-text="dba_name"
										@change="returnIDs($event)"
										label="Select Linked Accounts"
										multiple
									></v-combobox>
									</div>
								</div>
							</template>
							<template slot="profile-info">
								<div class="row">
									<div class="col-12">
										<v-file-input
											accept="image/*"
											label="File input"
											v-model="logo"
										></v-file-input>
									</div>
								</div>
							</template>

						</baseCompanyFormComponent>
			
					</v-col>
					<!-- END Main Content Left Area -->
					<!-- Right Panel -->
					<v-col cols="12" lg="6" class="px-2">
						<template>
							<v-card
								:min-width="800">
								<v-card-title class="mb-1 bg-primary text-white">
									<v-row class="p-2">
										<v-col cols=6>
											<div class="title">
												Merchant List
											</div>
										</v-col>
										<div class="col-6 flex items-center">
											<v-text-field
												class="m-0 p-0"
												color="yellow"
												dark
												v-model="search"
												append-icon="mdi-magnify"
												label="Search"
												single-line
												hide-details
											></v-text-field>
										</div>
									</v-row>
								</v-card-title>

								<dataTableComponent
									ref="merchantDataTableRef"
									:headerTitles="headers"
									:searchData="search"
									:listData="listData.list"
									@updateItem="updateItem"
									@clearData="clearData"
									@deleteItem="deleteItem">
								</dataTableComponent>

							</v-card>
						</template>

						<v-row class="mt-3" v-if="$can('view', 'superuser')">
							<v-col cols=12>
								<v-btn
									@click="testButton">
									test
								</v-btn>
							</v-col>
						</v-row>

					</v-col >
					<!-- END Right Panel -->
				</div>
				<!-- END Main Container Row -->
				
			</DxScrollView>
		</div>
	</div>
</template>

<script>
"use-strict";

import { mapState, mapGetters } from 'vuex'

import moment from 'moment'

// Mixins
import { FormMixins } from '@/mixins/form-mixins'

//Components
import baseCompanyFormComponent from "@/components/forms/base-company-form"
const dataTableComponent = import("@/components/tables/data-table-component")

import LoadingState from '@/components/component-management/loading-state-component'
import ErrorState from '@/components/component-management/error-state-component'

// Devexpress
import { DxScrollView } from 'devextreme-vue/scroll-view'

export default {
	name: 'superUserManageMerchant',
	metaInfo: { title: 'Add Merchants' },
  components: {
		DxScrollView,
		baseCompanyFormComponent,
		dataTableComponent: () => ({
			component: dataTableComponent,
			loading: LoadingState,
			error: ErrorState,
			//Delay before showoing loader
			delay:100,
			//Timeout to show error component
			timeout: 5000
		}),
  },
  mixins: [
    FormMixins
  ],
  props: {

  },
  data () {
    return {
      // Main Data
			linkedAccounts: [],

      // Component Data
			search: '',
			headers: [
				{	text: "ID",	align: 'center',	sortable: true,	value: 'id' },
				{ text: 'Linked', align: 'center',	sortable: true, value: 'linked_account' },
				{ text: 'DBA Name', align: 'center',	sortable: true, value: 'dba_name' },
				{ text: 'Merchant #', align: 'center',	sortable: true, value: 'merchant_id' },
				{ text: 'Domain', align: 'center',	sortable: true, value: 'domain' },
				{ text: 'Primary Email', align: 'center',	sortable: true, value: 'primary_email' },
				{ text: 'Is Active', align: 'center',	sortable: true, value: 'is_active' },
				
			],
			
			listData: {
				tableId: "merchantListTable",
				list: new Array()
			},

			// Form Data
			logo: null,
			merchantForm: {
				linked_account: [],
				id: null,
				logo: null,
			},
			baseCompanyForm: null

    }
  },
  methods: {
    testButton() {
			// console.log("this.linkedAccounts", this.linkedAccounts);
			// console.log("this.merchantForm", this.merchantForm);
			// console.log("companyForm", this.baseCompanyForm);
			console.log("GET_MERCHANT_LIST", this.GET_MERCHANT_LIST);
		},
		returnIDs(evt) {
			// console.log('returnIDs', evt)
			this.merchantForm.linked_account = []
			for(let key in evt) {
				this.merchantForm.linked_account.push(evt[key].id)
			}
		},
		//Update the linked accounts to the fields
		async updateItem(evt) {
			// console.log('updateItem evt', evt)
			this.linkedAccounts = []

			//Update Linked Accounts
			for(let key in evt.linked_account) {
				let account = this.GET_MERCHANT_LIST.find(itm => itm.id === evt.linked_account[key])
				// console.log('acocunt', account)
				this.linkedAccounts.push(account)
			}
			
			this.$refs.baseCompanyFormRef.updateForm(evt)
			
		},
		clearCheckbox(evt) {
			// console.log("clearCheckbox");
			// clear checkbox on datatable
			// console.log("this.$refs", this.$refs);
			this.$refs.merchantDataTableRef.clearItem()
		},
		clearData() {
			// console.log("ClerDataParentMerchant")
			// console.log("this.merchantForm",this.merchantForm);
			// console.log("this.baseCompanyForm", this.baseCompanyForm);
			// console.log("this.$parent", this.$parent);
			// console.log("this.$parent", this.$parent.$children[0].$children[0].$children[0]);
			// console.log("this.$refs", this.$refs);

			for(let key in this.merchantForm) {
				this.merchantForm[key] = null
			}

			this.merchantForm.linked_account = []
			this.linkedAccounts = []

			this.$refs.baseCompanyFormRef.resetForm()
			this.baseCompanyForm = null
		},
		GetCompanyData(evt) {
			// console.log("GetCompanyData evt", evt)
			this.baseCompanyForm = evt
			// console.log("baseCompanyForm", this.baseCompanyForm)
		},
		async createMerchant() {
			//Create Merchant
			try {
				// console.log('this.baseCompanyForm', this.baseCompanyForm)

				if(this.logo) {
					// console.log("this.logo)", this.logo);
					var profileReader = new FileReader();
					profileReader.readAsDataURL(this.logo);
					profileReader.onload = async (e) => {
						this.merchantForm.logo = e.target.result;
						// console.log("this.merchantForm.logo", this.merchantForm.logo);

						var joinedForm = Object.assign({}, this.merchantForm, this.baseCompanyForm)
						// console.log('259 joinedForm', joinedForm)

						//Create Merchant
						let merchantResponse = await this.$store.dispatch("POSTMerchant", joinedForm)
						// console.log("merchantResponse", merchantResponse)
						if(merchantResponse) {
							this.$refs.baseCompanyFormRef.resetForm()
							this.Notifications.snackBarStack.push( {message:"Created",
																										color:"success",
																										timeout:5000
																									})
							// Clear Form afer creation
							this.$refs.baseCompanyFormRef.$refs.companyFormRef.resetForm()
							this.clearData()

							// Make call to update Merchant List
							await this.$store.dispatch('GETMerchantList')
							this.mountData()
						}

					};
				} else {
					//Create Merchant
					var joinedForm = Object.assign({}, this.merchantForm, this.baseCompanyForm)
					let merchantResponse = await this.$store.dispatch("POSTMerchant", joinedForm)
					// console.log("merchantResponse", merchantResponse)
					if(merchantResponse) {
						// Clear Form afer creation
						this.$refs.baseCompanyFormRef.$refs.companyFormRef.resetForm()
						this.clearData()

						// Make call to update Merchant List
						await this.$store.dispatch('GETMerchantList')
						this.mountData()
					}
				}
					
			} catch(error) {
				// console.error('error', error)
			}
		},
		async updateMerchant() { // Make PUT or PATCH calls
		console.log("updateMerchant Called");
			if(this.logo) {
				// console.log("this.logo)", this.logo);
				var profileReader = new FileReader();
				profileReader.readAsDataURL(this.logo);
				profileReader.onload = async (e) => {
					this.merchantForm.logo = e.target.result;
					// console.log("this.merchantForm.logo", this.merchantForm.logo);

					var joinedForm = Object.assign({}, this.merchantForm, this.baseCompanyForm)
					// console.log('300 joinedForm', joinedForm)

					let response = await this.$store.dispatch('PATCHMerchant', joinedForm);
					if(response.status === 200 || response.status === 201 ) {
						this.companyForm.id = null
						this.clearData()
						this.$refs.baseCompanyFormRef.resetForm() // My custom resetter
						// this.$refs.baseCompanyFormRef.$refs.companyFormRef.resetForm()

						// Make call to update Merchant List
						await this.$store.dispatch('GETMerchantList')
						this.mountData()
					}
				}
			} else {
				var joinedForm = Object.assign({}, this.merchantForm, this.baseCompanyForm)
				// console.log('joinedForm', joinedForm)

				let response = await this.$store.dispatch('PATCHMerchant', joinedForm);

				if(response.status === 200 || response.status === 201 ) {
					this.companyForm.id = null
					this.clearData()
					this.$refs.baseCompanyFormRef.resetForm()

					// Make call to update Merchant List
					await this.$store.dispatch('GETMerchantList')
					this.mountData()
				}
			}			
		},
		mountData() {
			this.listData.list = this.GET_MERCHANT_LIST
		},
		async deleteItem(evt) {
			// console.log('deleteItem evt', evt)
			const foundMerObj = {id: evt.id, is_active: false}
			let response = await this.$store.dispatch("PATCHMerchant", foundMerObj)
			// console.log('delete response', response)
		}
		

  },
  computed: {
    ...mapState(["Notifications"]),
		...mapGetters(["GET_MERCHANT_LIST"]),
		computeMerchantList() {
			if(this.baseCompanyForm) {
				return this.GET_MERCHANT_LIST.filter(itm => itm.id != this.baseCompanyForm.id)
			} else {
				return this.GET_MERCHANT_LIST
			}
			
		}

	},
	created() {
		// console.log('this.GET_MERCHANT_LIST', this.GET_MERCHANT_LIST)
		this.mountData()
		// console.log('this.listData', this.listData)
	},
  mounted () {

  }
}
</script>
<style scoped lang="scss">

</style>
