<template>
	<div class="flex justify-center">
		<div class="row">
			<div class="col-12">
				<v-form
					ref="merchantFormRef"
					v-model="validMerchant">
				<v-select
					v-model.sync="parentForm.merchant"
					:items="GET_MERCHANT_LIST"
					item-text="dba_name"
					item-value="id"
					:rules="merchantSelectRules"
					label="Select Merchant"
					disabled
				></v-select>
				</v-form>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import moment from 'moment'

// Mixins
import { FormMixins } from '@/mixins/form-mixins'

export default {
  name: 'parentSchoolSelectionComponent',
  components: {
  },
  mixins: [
		FormMixins
  ],
  props: {
		parentForm: {
			type: Object,
			required: true
		}
  },
  data () {
    return {
			// Main Data	
			validMerchant: false,


    }
  },
  methods: {
    testButton() {

		},
		validateForm() {
			return this.$refs.merchantFormRef.validate()
		},

  },
  computed: {
    ...mapState([]),
		...mapGetters(["GET_MERCHANT_LIST"]),


	},
  mounted () {
		// console.log("parentForm", this.parentForm);

	},
	beforeDestroy() {

	}
}
</script>

<style scoped lang="scss">

</style>
