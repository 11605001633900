import Vue from 'vue'
import Vuex from 'vuex'

import apiRoutes from '@/js/api-routes'

Vue.use(Vuex)

export const Merchants = {
  namespace: true,
  name: 'merchants',
  state: {
		merchantList: [],
		linkedList: [],
		merchantProfile: {},
		merchantOptions: {},
		selectedMerchantProfile: {},
		hpSelectedProfile: {}
  },
  mutations: {
		SET_HP_MERCHANT(state, payload) {
			Vue.set(state, 'hpSelectedProfile', payload);
			// state.hpSelectedProfile = payload
		},
		SET_MERCHANT_LIST(state, payload) {
			Vue.set(state, 'merchantList', payload);
		},
		PUSH_NEW_MERCHANT(state, payload) {
			state.merchantList.push(payload)
		},
		SET_MERCHANT_PROFILE(state, payload) {
			// console.log("SET_MERCHANT_PROFILE state1", state.merchantProfile);
			Vue.set(state, 'merchantProfile', payload);
			// console.log("SET_MERCHANT_PROFILE state2", state.merchantProfile);
			// const arrIndx = state.merchantList.findIndex((itm) => itm.id === payload.id)
			// state.merchantList.splice(arrIndx, 1, payload)
		},
		SET_SELECTED_MERCHANT_PROFILE(state, payload) {
			Vue.set(state, 'selectedMerchantProfile', payload);
			//Find merchent in table and update info
			const arrIndx = state.linkedList.findIndex((itm) => itm.id === payload.id)
			state.linkedList.splice(arrIndx, 1, payload)
		},
		SET_SELECTED_MERCHANT_LIST(state, payload) {
			const arrIndx = state.linkedList.findIndex((itm) => itm.id === payload.id)
			if(arrIndx>=0) {
				state.linkedList.splice(arrIndx, 1, payload)
			}else {
				state.linkedList.push(payload)
			}
			
		},
		CLEAR_MERCHANT_PROFILE(state, payload) {
			state.merchantProfile = {}
		},
		CLEAR_MERCHANT_STATE(state, payload) {
			state.merchantList= []
			state.linkedList= []
			state.merchantProfile= {}
			state.merchantOptions= {}
			state.selectedMerchantProfile = {}
			state.hpSelectedProfile = {}
		}

  },
  actions: {
		//POST and CREATE Item
		async POSTMerchant({commit, dispatch, rootState}, payload) {
			return new Promise( async (resolve, reject) => {
				try {
					let endpoint = 'merchants/';
					let type = 'Create New Merchant';
					let response = await apiRoutes.POSTItem(dispatch, rootState,payload, endpoint, type)
					// console.log('POSTMerchant response', response);
					if(response.status === 201) {
						// console.log("Successful");
						commit('PUSH_NEW_MERCHANT', response.data);
						return resolve(response)
					} else {
						return resolve()
					}
				} catch (error) {
					return reject(error)
				}       
			}).catch(error => {
				return error;
			});
		},
		//PATCH / Update
		async PATCHMerchant({commit, dispatch, rootState}, payload) {
			return new Promise( async (resolve, reject) => {
				try {
					let endpoint = 'merchants/';
					let type = 'Update Merchant';
					let response = await apiRoutes.PATCHItemById(dispatch, rootState,payload, endpoint, type)
					// console.log('PATCHMerchant response', response);
					if(response.status === 200 || response.status === 201) {
						// console.log("Successful");

						commit('SET_SELECTED_MERCHANT_PROFILE', response.data);

						return resolve(response)
					} else {
						return reject(response)
					}
				} catch (error) {
					return reject(error)
				}       
			}).catch(error => {
				return error;
			});
		},

		//GET LISTS
		async GETMerchantList({commit, dispatch, rootState}) {
			return new Promise( async (resolve, reject) => {
				try {
					let endpoint = 'merchants-list/';
					let type = 'Get Merchants List';
					let response = await apiRoutes.GETList(dispatch, rootState, endpoint, type);
					// console.log('GETMerchantsList response', response);
					commit('SET_MERCHANT_LIST', response.data);
					return resolve(response.data)

				} catch(error) {
					// console.error('TryCatch Error error.response', error.response)
					return reject(error)
				}
			}).catch(error => {
				// console.error('Promise Error error.response', error.response)
				return error;
			});
		},

		// GET Logged In Merchant Profile Profile By Id
		GETMerchantProfileById({commit, dispatch, rootState}, payload) {
			return new Promise(async (resolve, reject) => {
				// console.log('GETMerchantProfileById payload', payload)
				try {
					let endpoint = 'merchants/'
					let type = 'Get Merchant Profile'
					let response = await apiRoutes.GETProfileById(dispatch, rootState, payload, endpoint, type)
					if (response.status === 200) {
						// console.log('Get by id response', response.data)
						commit('SET_MERCHANT_PROFILE', response.data)
						return resolve(response.data)
					} else {
						return reject({ message: response })
					}
				} catch (error) {
					return reject(error)
				}
			}).catch(error => {
				// console.log('error', error)
				if (error.response) {
					// console.log('error.response', error.response)
					// dispatch('updateNotification', error.response);
					return reject(error)
				}
			})
		},
		// GET Merchant by Domain
    GETMerchantByDomain({ commit, dispatch, rootState }, payload) {
      // console.log('GETMerchantByDomain')
      return new Promise(async (resolve, reject) => {
        try {
          // console.log('GETMerchantByDomain payload', payload)
          let endpoint = 'merchants/?domain='
          let type = 'Get Merchant Profile'
          let response = await apiRoutes.GETProfileByDomain(dispatch, rootState, payload, endpoint, type)
          if (response.status === 200) {
            // console.log('GETMerchantByDomain response', response)
            commit('SET_HP_MERCHANT', response.data[0])
            return resolve(response.data[0])
          } else {
            return reject({ message: response })
          }
        } catch (error) {
          return reject(error)
        }
      }).catch(error => {
        // console.log('error', error)
        if (error.response) {
          // console.log('error.response', error.response)
          // dispatch('updateNotification', error.response);
          return reject(error)
        }
      })
		},
		//GET Selected Items of Subordinate accounts
		GETSelectedMerchantById({ commit, dispatch, rootState }, payload) {
			// console.log('GETSelectedMerchantById payload', payload)
			return new Promise(async (resolve, reject) => {
				try {
					let endpoint = 'merchants/'
					let type = 'Get Merchant Profile'
					let response = await apiRoutes.GETProfileById(dispatch, rootState, payload, endpoint, type)
					if (response.status === 200) {
						// console.log('GETSelectedMerchantById response', response)
						commit('SET_SELECTED_MERCHANT_PROFILE', response.data)
						return resolve(response.data)
					} else {
						return reject({ message: response })
					}
				} catch (error) {
					return reject(error)
				}
			}).catch(error => {
				// console.log('error', error)
				if (error.response) {
					// console.log('error.response', error.response)
					// dispatch('updateNotification', error.response);
					return reject(error)
				}
			})
		},
		//GET Selected Items of Subordinate accounts
		GETSelectedMerchantList({ commit, dispatch, rootState }, payload) {
			// console.log('GETSelectedMerchantById payload', payload)
			return new Promise(async (resolve, reject) => {
				try {
					let endpoint = 'merchants/'
					let type = 'Get Merchant Profile'
					let response = await apiRoutes.GETProfileById(dispatch, rootState, payload, endpoint, type)
					if (response.status === 200) {
						// console.log('GETSelectedMerchantById response', response)
						commit('SET_SELECTED_MERCHANT_LIST', response.data)
						return resolve(response.data)
					} else {
						return reject({ message: response })
					}
				} catch (error) {
					return reject(error)
				}
			}).catch(error => {
				// console.log('error', error)
				if (error.response) {
					// console.log('error.response', error.response)
					// dispatch('updateNotification', error.response);
					return reject(error)
				}
			})
		},
		clearMerchantState({ commit, dispatch, rootState }) {
			commit("CLEAR_MERCHANT_STATE")
		},


  },
  getters: {
		GET_MERCHANT_LIST(state) {
			return state.merchantList
		},
		GET_LINKED_LIST(state) {
			return state.linkedList
		},
		GET_MERCHANT_LENGTH(state) {
			return state.merchantList.length
		},
		GET_MERCHANT_PROFILE(state) {
			// console.log("state.merchantProfile",state.merchantProfile);
			return state.merchantProfile
		},
		GET_SELECTED_MERCHANT_PROFILE(state) {
			return state.selectedMerchantProfile
		},
		GET_HP_MERCHANT_PROFILE(state) {
			return state.hpSelectedProfile
		},

  }
}
