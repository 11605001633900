export const FormMixins = {
  data () {
    return {
			//Base User Form
			mobile_phone: "",
			fax_phone: null,
			userForm: {
				id: null,
				date_added: null,
				date_closed: null,
				last_login: null,
				full_name: null,
				first_name: "",
				last_name: "",
				email: "",
				password: "",
				mobile_phone: "",
				fax: null,
				address: null,
				address2: null,
				city: null,
				state: null,
				zip_code: null,
				groups: [],
			},
			primary_phone: "",
			primary_fax: "",
			shipping_phone: "",
			shipping_fax: "",
			companyForm: {
				id: null,
				dba_name: "",
				legal_name: "",
				domain: "",
				merchant_id: null,
				date_added: null,
				date_closed: null,
				primary_email: "",
				primary_first_name: "",
				primary_last_name: "",
				primary_phone: "",
				primary_fax: "",
				primary_address: "",
				primary_address2: "",
				primary_city: "",
				primary_state: "",
				primary_zip_code: "",
				primary_country: null,
				billing_email: null,
				billing_first_name: null,
				billing_last_name: null,
				billing_phone: null,
				billing_fax: null,
				billing_address: null,
				billing_address2: null,
				billing_city: null,
				billing_state: null,
				billing_zip_code: null,
				billing_country: null,
				shipping_email: null,
				shipping_first_name: null,
				shipping_last_name: null,
				shipping_phone: null,
				shipping_fax: null,
				shipping_address: null,
				shipping_address2: null,
				shipping_city: null,
				shipping_state: null,
				shipping_zip_code: null,
				shipping_country: null,
				description: null,
				notes: null,
				is_active: false,
				is_locked: false,
			},
			
      // Vuetify Rules
			valid: true,
      nameRules: [
        v => !!v || 'This field is required',
        v => /[A-Za-z]+/.test(v) || 'Letters only'
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
			],
			phoneRules: [
        v => !!v || 'This field is required',
				v => /\([2-9]\d{2}\)\s\d{3}-\d{4}/.test(v) || 'Phone must be valid',
				// v => /\([2-9]\d{2}\)\s\d{3}-\d{4}/.test(v) || /\d{10}/.test(v) || 'Phone must be valid',
			],
     	faxRules: [
				v => (v && v.length > 0 && !/\([2-9]\d{2}\)\s\d{3}-\d{4}/.test(v)) ? 'Fax must be valid' : true
			],
			stateRules: [
				v => !!v || 'State is required',
				v => /[A-Za-z]+/.test(v) || 'Letters only',
				v => v? v.length <= 2 : 'No more than 2 digits'
			],
			//Not required but still validate by rules
			zipRules: [
				v => v? v.length >=0? /^[\d ]*$/.test(v) : "Numbers Only": true,
				v => v? v.length >=0? v.length <= 5 : 'No more than 5 digits': true
			],
			passwordRules: [
        v => !!v || 'Password is required',
				// v => /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(v) || 'Password must have at least one Symbol',
				v => /[0-9]+/.test(v) || 'Password must have at least one number',
				v => /[a-zA-Z]+/.test(v) || 'Password must have at least one letter',
				v => /^.{6,}$/.test(v) || 'Password must have at least 6 characters',
			],
			merchantSelectRules: [
				v => !!v || 'Merchant is required',
			],
			requiredRule: [
				v => !!v || 'This field is required',
			],
			merchantIdRule: [
				v => !!v || 'This field is required',
				v => /^[\d ]*$/.test(v) || "Numbers Only",
				v => v? v.length <= 10 : 'No more than 10 digits'
			],
			creditCardRules: [
				v => !!v || 'This field is required',
				v => /^[\d ]*$/.test(v) || "Numbers Only",
				v => v? v.length <= 19 : 'No more than 16 digits'
			],
			expDateRules: [
				v => !!v || 'This field is required',
				v => /^[\d /]*$/.test(v) || "Numbers Only",
				v => v? v.length <= 7 : 'No more than 4 digits',
				// v => v? this.afterThisDate: 'Expiraton date must be in the future'
			],
			cvvRules: [
				v => !!v || 'This field is required',
				v => /^[\d /]*$/.test(v) || "Numbers Only",
				v => v? v.length <= 4 : 'No more than 4 digits'
			],
			amountRules: [
				v => !!v || 'This field is required',
				v => /^[\d /.]*$/.test(v) || "Numbers Only",
			],
			rules: {
				required: value => !!value || 'Required Field',
				min8Chars: v => v? v.length >= 8 : 'Min 8 characters',
				min6Chars:	v => /^.{6,}$/.test(v) || 'Min 6 characters',
				min1Num:	v => /[0-9]+/.test(v) || 'Min 1 number',
				min1Letter: v => /[a-zA-Z]+/.test(v) || 'Min 1 letter',
				lettersOnly: v => /^[a-zA-Z]*$/.test(v) || 'Letters Only',
				numbersOnly: v => /^[\d.]*$/.test(v)|| "Numbers Only",
				zipRule: v => v? v.length <= 5 : 'No more than 5 digits',
				email: v => v? v.length>0 && /.+@.+\..+/.test(v) ? true : 'E-mail must be valid' : true
			},

			//DevExpress Rules
			cardNumberRules: {
				X: /[0-9]/
			},
			expRules: {
				X: /[0-9]/
			},
    }
  },
  methods: {
    submitForm () {
      this.validate()
		},
		afterThisDate(data) { // Not used, need to fix
			var thisYear = new Date().getFullYear().toString().slice(2)
			console.log("data", data);
			console.log("thisT=Year", thisYear);
			console.log("this.creditCardForm.ssl_exp_date", this.creditCardForm.ssl_exp_date);
			return false
		},
		
    validate () {
      if(this.$refs.baseEmployeeFormRef) {
				this.$refs.baseEmployeeFormRef.validate()
			} else if(this.$refs.companyFormRef) {
				this.$refs.companyFormRef.validate()
			}
    },
    resetFormData (ref) {
			console.log("form-mixins this.$refs", this.$refs);

			switch(true) {
				case ref == "companyFormRef":
					console.log("there is a base companyFormRef");
					for(let key in this.companyForm) {
						this.companyForm[key] = null
					}
					
				// case this.$refs.baseEmployeeFormRef && typeof {...this.$refs.baseEmployeeFormRef} != null:
				// 	console.log("there is a base baseEmployeeFormRef");
				// 	this.$refs.baseEmployeeFormRef.reset()
					
				case ref == "baseUserFormRef":
					console.log("there is a base baseUserFormRef");
					for(let key in this.userForm) {
						this.userForm[key] = null
					}

			}	
    },
    resetValidation (ref) {
			ref.resetValidation()
    },
    deleteItem () {
      console.log('deleteItem clicked')
      console.log('this.selected', this.selected)
    }
	},
	computed: {
		comparePasswords() {
			if(this.confirmPassword) {
				if(this.userForm.password != this.confirmPassword) {
					return true
				}
			}
			return false
		},
		compareSetNewPassword() {
			if(this.confirmPassword) {
				if(this.newForm.new_password != this.confirmPassword) {
					return true
				}
			}
			return false
			
		},
	},
  mounted () {

  },
  created () {

  }
}
