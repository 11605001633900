<template>
	<div ref="scrollContainerRef"
		class="main-container">


			<!-- NavBar -->
			<websiteNavbarComponent 
				:scrollOffset="scrollHeight" 
				:formData="GET_HP_MERCHANT_PROFILE" />

			<div class="row justify-center">
				<div class="container">
					<div class="row">
						<div class="col-8">
							<template>
								<v-stepper
									class="no-box-shadow"
									v-model="step"
									vertical
								>
									<v-stepper-step
										:complete="step > 1"
										step="1"
										:editable="step > 1"
									>
										Customer Information
										<small>{{ customerForm.first_name }} {{ customerForm.last_name }}</small>
									</v-stepper-step>

									<v-stepper-content step="1">
										<v-card
											flat
											color="grey lighten-5"
											class="mb-6"
										> 
											<v-form
												v-model="validCustInfoForm"
												ref="custInfoFormRef">
												<div class="row">
													<div class="col-12">
														
														<div class="row">
															<div class="col-6">
																<div class="row">
																	<div class="field-title">
																		First Name
																	</div>
																</div>
																<div class="row">
																	<div class="field-content">
																		<v-text-field 
																			color="primary lighten-2"
																			placeholder="Jane"
																			v-model="customerForm.first_name"
																			:rules="nameRules">
																		</v-text-field>
																	</div>
																</div>
															</div>
															<div class="col-6">
																<div class="row">
																	<div class="field-title">
																		Last Name
																	</div>
																</div>
																<div class="row">
																	<div class="field-content">
																		<v-text-field 
																			placeholder="Doe"
																			v-model="customerForm.last_name"
																			:rules="nameRules">
																		</v-text-field>
																	</div>
																</div>
															</div>
														</div>
														<div class="row">
															<div class="col-6">
																<div class="row">
																	<div class="field-title">
																		Account #
																	</div>
																</div>
																<div class="row">
																	<div class="field-content">
																		<v-text-field 
																			color="primary lighten-2"
																			placeholder="123-123456"
																			v-model="customerForm.customer_id"
																			:rules="requiredRule">
																		</v-text-field>
																	</div>
																</div>
															</div>
															<div class="col-6">
																<div class="row">
																	<div class="field-title">
																		Invoice #
																	</div>
																</div>
																<div class="row">
																	<div class="field-content">
																		<v-text-field 
																			placeholder="(Optional)"
																			v-model="customerForm.invoice_number">
																		</v-text-field>
																	</div>
																</div>
															</div>
														</div>
														<div class="row">
															<div class="field-title">
																Email Address
															</div>
														</div>
														<div class="row">
															<div class="field-content">
																<v-text-field
																	v-model="customerForm.email"
																	placeholder="email@domain.com"
																	:rules="[rules.email]">
																</v-text-field>
															</div>
														</div>

														
													</div>
												</div>
											</v-form>
										</v-card>
										<div class="row justify-end mt-4">
											<div class="col-6 text-end">
												<v-btn text 
													class="mx-3"
													:to="{name: 'Home'}">
													Cancel
												</v-btn>
				
												<v-btn
													class="mx-3"
													color="primary"
													@click="completeStepOne"
												>
													Next
												</v-btn>
											</div>
										</div>

									</v-stepper-content>

						
									<v-stepper-step 
										:complete="step > 2"
										:editable="step > 2"
						
										step="2">
										Cardholder Details
										<small>{{ cardholderForm.first_name }} {{ cardholderForm.last_name }}</small>
									</v-stepper-step>
									<v-stepper-content step="2">
										<v-card
											color="grey lighten-5"
										>
											<cardholderDetailsComponent
												ref="cardHolderDetailsRef" 
												:form="cardholderForm" />

										</v-card>
										<div class="row justify-end mt-4">
											<div class="col-6 text-end">
												<v-btn 
													text
													class="mx-3"
													@click="step = 1">
													Back
												</v-btn>
												<v-btn
													class="mx-3"
													color="primary"
													@click="completeStepTwo"
												>
													Next
												</v-btn>
											</div>
										</div>
										
									</v-stepper-content>
									<v-stepper-step 
										:complete="step > 3"
						
										step="3">
										Credit Card Details
										<small>{{ cardScheme }}</small>
									</v-stepper-step>
									<v-stepper-content step="3">
										<v-card
											color="grey lighten-5"
										>
											<creditCardFormComponent 
												ref="creditCardFormRef"
												:creditCardForm="creditCardForm"
												@CardBinDetails="GetCardBinDetails" />

										</v-card>
										<div class="row justify-end mt-4">
											<v-btn text
												@click="step = 2">
												Back
											</v-btn>
										</div>
										
									</v-stepper-content>
								</v-stepper>
							</template>

						</div>

						<div class="col-4">
							<div class="row">
								<div class="col-12">
									<div class="headline mb-2">
										Enter Payment Amount
									</div>
								</div>
							</div>

							<checkoutListTableComponent 
								ref="checkoutTableRef"
								:form="creditCardForm"/>

						</div>
					</div>
				</div>

			</div>

		
		<template>
			<div class="row  justify-end">
				<div class="col-8 flex justify-center">
					<div class="row justify-center items-center">
						<v-img
							max-height="30"
							max-width="250"
							src="@/assets/static/4_Card_color_horizontal.png"
						></v-img>
					</div>
				</div>

				<div class="col-4  p-0 text-center">
					<div class="row">
						<div class="col-12 px-6">
							<v-btn
								width="100%"
								@click="startTransaction"
								color="success">
								Process Now
							</v-btn>
						</div>
					</div>
					<div class="row justify-center mt-2">
						<v-img
							class="my-3"
							max-height="50"
							max-width="100"
							src="@/assets/static/SSL Secure Logo2.png"
						></v-img>
					</div>

				</div>
			</div>
		</template>

		<!-- Dialog -->
		<v-dialog
			v-model="dialog"
			width="25%"
		>
			<v-card>
				<v-card-title class="headline grey lighten-2">
					Processing
				</v-card-title>

				<v-card-text class="text-center">
					<p class="my-3">
						Do not reload page.
					</p>
					<p class="my-3">
						Please wait...
					</p>
					<v-progress-circular
						indeterminate
						color="primary"
					></v-progress-circular>
				</v-card-text>
			
			</v-card>
		</v-dialog>

		<!-- Unsuccessful / Error Dialog -->
		<v-dialog
			v-model="unsuccessfulTrans"
			transition="dialog-bottom-transition"
			max-width="600"
		>
			<template v-slot:default="dialog">
				<v-card>
					<v-toolbar
						color="primary"
						dark
						class="text-h5"
					>
						Error Processing Transaction
					</v-toolbar>
					<v-card-text class="py-0">
						<div class="text-h6 mt-5">
							Error Code: {{ errorCode }}
						</div>
						<div class="mb-5">
							Error Message: {{ errorMsg }}
						</div>
					</v-card-text>
					<v-card-actions class="justify-end">
						<v-btn
							text
							@click="dialog.value = false"
						>Close</v-btn>
					</v-card-actions>
				</v-card>
			</template>
		</v-dialog>

		
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import axios from 'axios'

import moment from 'moment'

//Components
import websiteNavbarComponent from "@/components/website/nav-bar-component"
import creditCardFormComponent from "@/components/payment-processing/credit-card-form-component"
import checkoutListTableComponent from "@/views/e-commerce/components/checkout-list-table-component"
import cardholderDetailsComponent from "@/components/forms/cardholder-details-form"

//Mixins
import { FormMixins } from "@/mixins/form-mixins"
import { ElavonConvergeMixins } from "@/mixins/credit-card/elavon-converge"

// JS Files
import {codes} from "@/js/elavon/elavon_response_codes.js"
// console.log("codes", codes);

import { states } from "@/js/states"
 
export default {
  name: 'hostedPaymentPage',
  components: {
		websiteNavbarComponent,
		creditCardFormComponent,
		checkoutListTableComponent,
		cardholderDetailsComponent
  },
  mixins: [
		FormMixins,
		ElavonConvergeMixins
  ],
  props: {

  },
  data () {
    return {
			// Main Data
			scrollHeight: 0,
			scrollEventData: null,
			step: 1,

			// Transaction Dialog Popup
			dialog: false,

			//Unsuccessful Transaction Popup
			unsuccessfulTrans: false,
			errorCode: null,
			errorMsg: null,

			//Form Data
			validCustInfoForm: false,
			customerForm:{
				email: "",
				first_name: "",
				last_name: "",
				invoice_number: "",
				customer_id: "",
			},
			cardholderForm: {
				first_name: "",
				last_name: "",
				billing_address: "",
				billing_address2: "",
				billing_city: "",
				billing_state: "",
				billing_zip_code: "",
			},
			creditCardForm: {
				ssl_card_number: "",
				ssl_exp_date: "",
				ssl_cvv2cvc2: "",
				ssl_amount: "",
				ssl_transaction_type: "ccsale",
			},
			
			binListCardResponse: {
				"bank": {},
					"number": {},
					"scheme": "Bin error",
					"country": {
						"name": "United States of America",
						"emoji": "🇺🇸",
						"alpha2": "US",
						"numeric": "840",
						"currency": "USD",
						"latitude": 38,
						"longitude": -97
					}
			},
			cardType: null,
			cardScheme: null,

    }
  }, 
  methods: {
    testButton () {
			// console.log('testButton')
			const transObj = {...this.creditCardForm, ...this.cardholderForm}
			// console.log('transObj', transObj)

		},
		async recaptcha() {
			return new Promise(async (resolve, reject) => {
				try {
					await this.$recaptchaLoaded()

					// Execute reCAPTCHA with action "transaction".
					const token = await this.$recaptcha("recaptchaTxn")
					// console.log('recaptcha token', token)

					let response = await this.$store.dispatch("POSTCaptcha", {merchant: this.GET_HP_MERCHANT_PROFILE.id, token: token})
					// console.log('recaptcha response', response)
					return resolve(response)

				} catch (error) {
					return reject(error)
				}
			})
		},
		async startTransaction() {
			await this.submitTransaction()
		},

		async submitTransaction() {
			new Promise( async (resolve, reject)=> {
				let validationCC = this.$refs.creditCardFormRef.$refs.validateFormRef.validate()
				// console.log('validationCC', validationCC)
				let amountValidation = this.$refs.checkoutTableRef.$refs.formValidationRef.validate()
				// console.log('amountValidation', amountValidation)
				if(validationCC) {
					if(amountValidation) {
						try {
							//Send captcha request first
							var captcha = await this.recaptcha()
							// console.log('captcha', captcha)

							this.dialog = true
							// console.log('submitTransaction')
							const transObj = Object.assign({}, 
								this.creditCardForm, 
								this.cardholderForm, 
								{customer: this.customerForm,
									merchant:this.GET_HP_MERCHANT_PROFILE.id, 
									ssl_avs_address: this.cardholderForm.billing_address,
									ssl_avs_zip: this.cardholderForm.billing_zip_code,
									card_scheme: this.cardScheme,
									captcha:captcha.token},
								) 
							// console.log('transObj', transObj)
							let transaction = await this.$store.dispatch("POSTConvergeSale", transObj)
							// console.log('transaction submitted response', transaction)

							if(transaction.status === 400) {
								this.dialog = false
								this.unsuccessfulTrans = true
								this.errorCode = "400"
								this.errorMsg = transaction.statusText
								throw "Error Processing Transaction!!!!!!!"

							}else if(transaction.status === 429) {
								this.dialog = false
								this.unsuccessfulTrans = true
								this.errorCode = "429"
								this.errorMsg = transaction.data.detail
								throw "Error Processing Transaction!!!!!!!"

							}else if(transaction.status === 500) {
								this.dialog = false
								this.unsuccessfulTrans = true
								this.errorCode = "500"
								this.errorMsg = transaction.data.split(/\n/)[1]
								throw "Error Processing Transaction!!!!!!!"

							}else if(transaction.status === 200 || transaction.status === 201 || transaction.status === 202) {
								// console.log("transaction", transaction);
								// console.log("transaction.data.ssl_result_message", transaction.data.ssl_result_message);
								// ********************** SUCCESSFUL **********************//

								this.dialog = false
								this.unsuccessfulTrans = false
								this.errorCode = transaction.status

								if(transaction.data.ssl_result_message === "APPROVAL") {
									const parameters = {transactionData:this.Converge.saleResponse, 
									merchantData:this.GET_HP_MERCHANT_PROFILE, 
									customerData: this.customerForm,
									cardholderData: this.cardholderForm,
									cardScheme: transaction.data.ssl_card_short_description}
									// console.log('parameters', parameters)
									this.dialog = false

									//Send Emails
									await this.sendMerchantNotification(transaction)
									if(this.customerForm.email) {
										await this.sendCustomerReceiptEmail(transaction)
									}
									
									this.$router.push({name: 'Transaction Receipt', params:  parameters })

									return resolve("Success")

								}// ********************** UNSUCCESSFUL **********************//
								else if(typeof await this.checkResponseCodes(transaction.data.errorCode) == 'object') {
									// console.log('typeof checkResponseCodes() == object');

									// Process Wrong Card #
									this.dialog = false
									this.unsuccessfulTrans = true
									this.errorCode = "Declined"
									this.errorMsg = transaction.data.errorName
									this.clearCCForm()
									throw "Error Processing Transaction!!!!!!!"

								}else if(transaction.data.ssl_result_message.startsWith("DECLINED")) {
									// console.log("Transaction contains DECLINED");
									// Process Legitimate cards and retrieve result
									this.dialog = false
									this.unsuccessfulTrans = true
									this.errorCode = "Declined"
									this.errorMsg = transaction.data.ssl_result_message
									this.clearCCForm()
									throw "Error Processing Transaction!!!!!!!"

								}else if(transaction.data.ssl_result_message === "DECLINE CVV2") {
									this.dialog = false
									this.unsuccessfulTrans = true
									this.errorCode = "Declined CVV2"
									this.errorMsg = transaction.data.ssl_result_message
									this.clearCCForm()
									throw "Error Processing Transaction!!!!!!!"

								} else if(transaction.data.ssl_result_message === "EXPIRED CARD") {
									this.dialog = false
									this.unsuccessfulTrans = true
									this.errorCode = "Expired Card"
									this.errorMsg = transaction.data.ssl_result_message
									this.clearCCForm()
									throw "Error Processing Transaction!!!!!!!"

								} else if(transaction.data.ssl_result_message === "AMOUNT ERROR") {
									this.dialog = false
									this.unsuccessfulTrans = true
									this.errorCode = "Declined"
									this.errorMsg = transaction.data.ssl_result_message
									this.clearCCForm()
									throw "Error Processing Transaction!!!!!!!"

								} else if(transaction.data.ssl_result_message === "AMT OVER SVC LMT") {
									this.dialog = false
									this.unsuccessfulTrans = true
									this.errorCode = "Amount Limit Error"
									this.errorMsg = transaction.data.ssl_result_message
									this.clearCCForm()
									throw "Error Processing Transaction!!!!!!!"

								} else if(transaction.data.ssl_result_message === "DECLINED-HELP 9999") {
									this.dialog = false
									this.unsuccessfulTrans = true
									this.errorCode = "System Error"
									this.errorMsg = transaction.data.ssl_result_message
									this.clearCCForm()
									throw "Error Processing Transaction!!!!!!!"

								} else if(transaction.data.ssl_result_message === "PICKUP CARD") {
									this.dialog = false
									this.unsuccessfulTrans = true
									this.errorCode = "Card Lost or Stolen"
									this.errorMsg = transaction.data.ssl_result_message
									this.clearCCForm()

									// If this is thrown, I need to immediately put the IP address in jail
									throw "Error Processing Transaction!!!!!!!"

								} else if(transaction.data.ssl_result_message === "INCORRECT PIN") {
									this.dialog = false
									this.unsuccessfulTrans = true
									this.errorCode = "Incorrect Pin"
									this.errorMsg = transaction.data.ssl_result_message
									this.clearCCForm()
									throw "Error Processing Transaction!!!!!!!"

								} else if(transaction.data.ssl_result_message === "DECLINED CVV2") {
									this.dialog = false
									this.unsuccessfulTrans = true
									this.errorCode = "Incorrect CVV"
									this.errorMsg = transaction.data.ssl_result_message
									this.clearCCForm()
									throw "Error Processing Transaction!!!!!!!"

								} else if(transaction.data.ssl_result_message === "INVALID CARD") {
									this.dialog = false
									this.unsuccessfulTrans = true
									this.errorCode = "Card Error"
									this.errorMsg = transaction.data.ssl_result_message
									this.clearCCForm()
									throw "Error Processing Transaction!!!!!!!"

								}	else if(transaction.data.ssl_result_message === "CALL AUTH CENTER") {
									this.dialog = false
									this.unsuccessfulTrans = true
									this.errorCode = "800-834-0409"
									this.errorMsg = transaction.data.ssl_result_message
									this.clearCCForm()
									throw "Error Processing Transaction!!!!!!!"

								} else if(transaction.data.ssl_result_message === "PLEASE RETRY5305") {
									this.dialog = false
									this.unsuccessfulTrans = true
									this.errorCode = "Retry Transaction"
									this.errorMsg = transaction.data.ssl_result_message
									this.clearCCForm()
									throw "Error Processing Transaction!!!!!!!"

								} else if(transaction.data.ssl_result_message === "PLEASE RETRY5270") {
									this.dialog = false
									this.unsuccessfulTrans = true
									this.errorCode = "Retry Transaction"
									this.errorMsg = transaction.data.ssl_result_message
									this.clearCCForm()
									throw "Error Processing Transaction!!!!!!!"

								} else if(transaction.data.ssl_result_message === "INVALID TERM ID") {
									this.dialog = false
									this.unsuccessfulTrans = true
									this.errorCode = "Terminal ID"
									this.errorMsg = transaction.data.ssl_result_message
									this.clearCCForm()
									throw "Error Processing Transaction!!!!!!!"

								} else if(transaction.data.ssl_result_message === "INVLD TERM ID 1") {
									this.dialog = false
									this.unsuccessfulTrans = true
									this.errorCode = "Merchant Number"
									this.errorMsg = transaction.data.ssl_result_message
									this.clearCCForm()
									throw "Error Processing Transaction!!!!!!!"

								} else if(transaction.data.ssl_result_message === "	MAX MONTHLY VOL") {
									this.dialog = false
									this.unsuccessfulTrans = true
									this.errorCode = "Max Monthly Volume"
									this.errorMsg = transaction.data.ssl_result_message
									this.clearCCForm()
									throw "Error Processing Transaction!!!!!!!"

								} else if(transaction.data.ssl_result_message === "INVLD TERM ID 2") {
									this.dialog = false
									this.unsuccessfulTrans = true
									this.errorCode = "Merchant Number - Amex"
									this.errorMsg = transaction.data.ssl_result_message
									this.clearCCForm()
									throw "Error Processing Transaction!!!!!!!"

								} else if(transaction.data.ssl_result_message === "NETWORK ERROR") {
									this.dialog = false
									this.unsuccessfulTrans = true
									this.errorCode = "System Error"
									this.errorMsg = transaction.data.ssl_result_message
									this.clearCCForm()
									throw "Error Processing Transaction!!!!!!!"

								} else if(transaction.data.ssl_result_message === "NETWORK ERROR 00") {
									this.dialog = false
									this.unsuccessfulTrans = true
									this.errorCode = "Network Error"
									this.errorMsg = transaction.data.ssl_result_message
									this.clearCCForm()
									throw "Error Processing Transaction!!!!!!!"

								} else if(transaction.data.ssl_result_message === "MICR ERROR") {
									this.dialog = false
									this.unsuccessfulTrans = true
									this.errorCode = "Card Read Error"
									this.errorMsg = transaction.data.ssl_result_message
									this.clearCCForm()
									throw "Error Processing Transaction!!!!!!!"

								} else if(transaction.data.ssl_result_message === "MUST SETTLE MMDD") {
									this.dialog = false
									this.unsuccessfulTrans = true
									this.errorCode = "Must Close Batcch > 7 Days"
									this.errorMsg = transaction.data.ssl_result_message
									this.clearCCForm()
									throw "Error Processing Transaction!!!!!!!"

								} else if(transaction.data.ssl_result_message === "PLEASE RETRY") {
									this.dialog = false
									this.unsuccessfulTrans = true
									this.errorCode = "Reenter Transaction"
									this.errorMsg = transaction.data.ssl_result_message
									this.clearCCForm()
									throw "Error Processing Transaction!!!!!!!"

								} else if(transaction.data.ssl_result_message === "SEQ ERR PLS CALL") {
									this.dialog = false
									this.unsuccessfulTrans = true
									this.errorCode = "Call Support. (855) 699-3282"
									this.errorMsg = transaction.data.ssl_result_message
									this.clearCCForm()
									throw "Error Processing Transaction!!!!!!!"

								} else if(transaction.data.ssl_result_message === "SERV NOT ALLOWED") {
									this.dialog = false
									this.unsuccessfulTrans = true
									this.errorCode = "Service Not Allowed"
									this.errorMsg = transaction.data.ssl_result_message
									this.clearCCForm()
									throw "Error Processing Transaction!!!!!!!"

								} else if(transaction.data.data.detail === "Request was throttled.") {
									this.dialog = false
									this.unsuccessfulTrans = true
									this.errorCode = "Excessve Use Violation"
									this.errorMsg = "Too many transactions have occured in a short period of time, please try again later."
									this.clearCCForm()
									throw "Error Processing Transaction!!!!!!!"

								} else {
									this.dialog = false
									this.unsuccessfulTrans = true
									this.errorCode = "Unknown Error"
									this.errorMsg = "Please contact Datacom Business Systems. (855) 699-3282"
									this.clearCCForm()
									throw "Error Processing Transaction!!!!!!!"
								}
							}  

						} catch(e){
							this.dialog = false
							// console.error("error", e)
							return resolve()
						}
					}
				}
			})
			
		},
		async checkResponseCodes(id) {
			// console.log("checking response codes");
			// console.log("codes", codes);

			for(let key in codes) {
				// console.log("code", code);
				if(codes[key].code == id) {
					// console.log("code", codes[key]);
					return codes[key]
				}
			}

			return false

		},
		async loadMerchantInfo(domain) {
			return new Promise( async (resolve, reject) => {
				try {
					// console.log('loadMerchantInfo, domain', domain)
					// console.log('this.GET_HP_MERCHANT_PROFILE', this.GET_HP_MERCHANT_PROFILE)
					// console.log('Object.keys(this.GET_HP_MERCHANT_PROFILE)', Object.keys(this.GET_HP_MERCHANT_PROFILE))
					if(Object.keys(this.GET_HP_MERCHANT_PROFILE).length == 0) {
						var response = await this.$store.dispatch("GETMerchantByDomain", domain)
						// console.log('get merchant by domain response', response)
						return resolve(response)
					}
				} catch (error) {
					// console.error('loadMerchantInfo error', error)
				}
			})
		},
		completeStepOne() {
			let validation = this.$refs.custInfoFormRef.validate()
			// console.log('validaiton', validation)
			if(validation) {
				this.step = 2
			}
		},
		completeStepTwo() {
			let validation = this.$refs.cardHolderDetailsRef.validate()
			// console.log('validaiton', validation)
			if(validation) {
				this.step = 3
			}
		},
		completeStepThree() {
			let validation = this.$refs.creditCardFormRef.validate()
			// console.log('validaiton', validation)
			if(validation) {
				this.step = 3
			}
		},
		
		GetCardBinDetails(evt) {
			// console.log("GetCardBinDetails evt", evt)
			Object.assign(this.binListCardResponse, evt) 
			this.cardScheme = evt
		},
		sendCustomerReceiptEmail(trans) {
			return new Promise(async(resolve, reject) => {
				// console.log('sendCustomerReceiptEmail', trans)
				// console.log('this.GET_EMAIL_SETTINGS', this.GET_EMAIL_SETTINGS)
				var custObj = {
					customer_name: this.customerForm.first_name + " " + this.customerForm.last_name,
					customer_email: this.customerForm.email,
					customer_number: this.customerForm.customer_id,
					invoice_number: this.customerForm.invoice_number,
					total_paid: this.creditCardForm.ssl_amount,
					merchant_name: this.GET_HP_MERCHANT_PROFILE.legal_name,
					card_number: trans.data.ssl_card_number,
					card_type: trans.data.ssl_card_short_description,
					card_scheme: this.cardScheme,
					approval_code: trans.data.approval_code,
					approval_msg: trans.data.ssl_result_message,
				}
				this.$store.dispatch("SendReceipt", custObj)
				resolve("Customer Email sent")
			})
			

		},
		sendMerchantNotification(trans) {
			return new Promise(async(resolve, reject) => {
				// console.log('sendMerchantNotification', trans)
				// console.log('this.GET_HP_MERCHANT_PROFILE', this.GET_HP_MERCHANT_PROFILE)
				var merchantEmailObj = {
					merchant_name: this.GET_HP_MERCHANT_PROFILE.legal_name,
					merchant_email: this.GET_HP_MERCHANT_PROFILE.primary_email,
					customer_name: this.customerForm.first_name + " " + this.customerForm.last_name,
					customer_number: this.customerForm.customer_id,
					invoice_number: this.customerForm.invoice_number,
					total_paid: this.creditCardForm.ssl_amount,
					transaction_id: trans.data.ssl_txn_id,
					card_number: trans.data.ssl_card_number,
					card_type: trans.data.ssl_card_short_description,
					transaction_type: trans.data.ssl_transaction_type,
					approval_msg: trans.data.ssl_result_message,
					card_scheme: this.cardScheme,
					approval_code: trans.data.approval_code
				}		

				this.$store.dispatch("SendTransNotification", merchantEmailObj)
				resolve("Merchant Email sent")
			})
			
		},
		clearCCForm() {
			this.creditCardForm.ssl_card_number = ""
			this.creditCardForm.ssl_exp_date = ""
			this.creditCardForm.ssl_cvv2cvc2 = ""
			this.$refs.creditCardFormRef.$refs.validateFormRef.resetValidation()
		},
		clearAllForms() {
			for(let key in this.customerForm) {
				this.customerForm[key] = ""
			}
			for(let key in this.cardholderForm) {
				this.cardholderForm[key] = ""
			}
			this.clearCCForm()
		}


  },
  computed: {
    ...mapState([]),
    ...mapGetters(["GET_HP_MERCHANT_PROFILE", "GET_EMAIL_SETTINGS"])

  },
  async mounted () {
		//Get location domain and load the merchant information accordingly
		const location= window.location.hostname
		const domain = location.split(".")[0]
		// console.log('domain', domain)

		let response = await this.loadMerchantInfo(domain)
		// console.log('HPP Mounted response', response)
		if(response) {
			if(response.is_active === false) {
				//Clear merchant data and throw popup
				this.$store.commit("CLEAR_MERCHANT_PROFILE")
				this.$store.dispatch("notificationPopup", {title: "Closed Account", body: "This account is no longer active"})
			}
		} else {
			this.$store.dispatch('notificationPopup', {title: 'Page Error', body: "Account is no longer active"})
		}
  },
	beforeUnmount() {
		// console.log("Unmounting");
		this.$store.dispatch("clearMerchantState")
	}
}
</script>
<style scoped lang="sass">
.main-container
	min-height: 82.4vh
</style>
