<template>
	<div>

		<div class="row">
			<v-app-bar
				app
				class="gradient-toolbar"
			>
				<template v-if="formData">
					<div class="col-1">
						<v-btn
              icon
							color="white"
							to="/merchant/post"
            >
              <v-icon>mdi-keyboard-backspace</v-icon>
            </v-btn>
					</div>

					<v-spacer></v-spacer>

					<div class="headline-4 text-white">
						{{ formData.legal_name }}
					</div>

					<v-spacer></v-spacer>
					<div class="text-white">
						<div class="text-sm">{{ formData.primary_address }} {{ formData.primary_address2 }}</div>
						<div class="text-sm">{{ formData.primary_city }} {{ formData.primary_state }}, {{ formData.primary_zip_code }}  </div>
						
					</div>

				</template>
				<template v-else>
					<div class="col-1">
						<v-img
							src="@/assets/static/No-Image-AvailableWhite.png"
							contain
							height="50"
							dark
						/>
					</div>

					<v-spacer></v-spacer>

					<div class="headline-4 text-white">
						*No Merchant Selected*
					</div>

				</template>
				
				

				<!-- <v-btn @click="testButton">
					Test
				</v-btn> -->

				
			</v-app-bar>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import moment from 'moment'

export default {
  name: 'websiteNavbarComponent',
  components: {

  },
  mixins: [

  ],
  props: [
		'formData'
	],
  data () {
    return {
			// Main Data

 

    }
  },
  methods: {
    testButton () {
			console.log('this.calcScrollOffset', this.calcScrollOffset)

		},

  },
  computed: {
    ...mapState([]),
		...mapGetters([]),

		
	},
	watch: {

	},
  mounted () {

  }
}
</script>
<style scoped lang="scss">
.gradient-toolbar {
	background-image: linear-gradient(to right, rgba(2, 36, 49, 0.6), rgb(52, 54, 54));
}
.gradient-appbar {
	width: 100%;
	max-height: 60px;
	background-image: linear-gradient(to right, rgba(2, 36, 49, 0.0), rgba(9, 73, 98, 0.6));
}
</style>
